import axios from 'axios';
import moment from 'moment';

const state = () => ({
	streak: null, // null | Streak | 'no-streak-yet'
});

const getters = {
	streak: (state) => {
		return state.streak;
	},
};

const mutations = {
	updateStreak(state, streak) {
		state.streak = streak;
	},
};

const actions = {
	async searchStreakByUserId(store, { userId }) {
		const res = await axios.post('/api/streaks/search', { userIds: [userId] });
		const { streaks } = res.data;
		if (streaks && streaks[0] == null) {
			store.commit('updateStreak', 'no-streak-yet');
			return res.data.streaks[0];
		} else if (streaks && streaks[0] != null) {
			store.commit('updateStreak', streaks[0]);
			return res.data.streaks[0];
		}
	},
	async submitDailyStreakByUserId(store, { userId }) {
		if (
			store.state.streak != null &&
			store.state.streak != 'no-streak-yet' &&
			store.state.streak.last_update_streak_time &&
			moment().isSame(moment(store.state.streak.last_update_streak_time), 'day')
		) {
			return; // last update streak is today so dont have to call api update until tomorrow
		}

		const res = await axios.post('/api/streaks/new-activity', { userId: userId });
		store.commit('updateStreak', res.data.streak);
	},
	async submitDailyStreakByFormationId(store, { formationId }) {
		const res = await axios.post('/api/streaks/new-activity', { formationId });
		store.commit('updateStreak', res.data.streak);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
