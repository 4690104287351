<template>
	<div v-if="renderBlank == false">
		<v-toolbar class="navbar" style="flex: none !important">
			<LogosColumn />
			<v-spacer></v-spacer>
			<!-- Login and Register page -->
			<template v-if="currentRoute.name === 'Login' || currentRoute.name == 'Register'">
				<div>
					<div v-if="currentRoute.name === 'Login'">
						<router-link :to="{ name: 'Register' }" id="register" class="navBarLink">Inscription</router-link>
					</div>
					<div v-else-if="currentRoute.name === 'Register'">
						<router-link to="/login" id="login" class="navBarLink">Connexion</router-link>
					</div>
				</div>
			</template>
			<!-- End Login and Register page -->

			<template v-else-if="userHaveFormations === true">
				<div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex">
					<TopicDropdownDesktop v-if="showTopicDropdown()" />
					<RedactionNavItemsDesktop
						v-if="showRedactionNav"
						:currentRoute="currentRoute"
						:parcoursId="formationInfos.parcours_id"
					/>
					<OrthographNavItemsDesktop v-else :currentRoute="currentRoute" :formationId="formationInfos._id" />
				</div>
				<div v-if="$vuetify.breakpoint.smAndDown">
					<RedactionNavItemsMobile
						v-if="showRedactionNav"
						:currentRoute="currentRoute"
						:formationId="formationInfos._id"
					/>
					<OrthographNavItemsMobile v-else :currentRoute="currentRoute" :formationId="formationInfos._id" />
				</div>
			</template>
			<template v-else-if="userHaveFormations === false">
				<OnlyHaveTestNav :currentRoute="currentRoute" />
			</template>
		</v-toolbar>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import LogosColumn from './LogosColumn';
import OrthographNavItemsDesktop from './OrthographNavItemsDesktop.vue';
import OrthographNavItemsMobile from './OrthographNavItemsMobile.vue';
import RedactionNavItemsDesktop from './RedactionNavItemsDesktop.vue';
import RedactionNavItemsMobile from './RedactionNavItemsMobile.vue';
import TopicDropdownDesktop from './TopicDropdownDesktop.vue';
import OnlyHaveTestNav from './OnlyHaveTestNav.vue';
import { showRedactionNavItems } from '@/utils/navbar.js';

export default {
	name: 'NavBar',
	components: {
		LogosColumn,
		OrthographNavItemsDesktop,
		OrthographNavItemsMobile,
		RedactionNavItemsDesktop,
		RedactionNavItemsMobile,
		TopicDropdownDesktop,
		OnlyHaveTestNav,
	},
	props: {
		formationInfos: Object,
		categoriesDone: Array,
		currentRoute: Object,
	},
	data() {
		return {
			userHaveFormations: null,
		};
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		renderBlank() {
			return ['LoginSSO'].includes(this.currentRoute.name);
		},
		showRedactionNav() {
			const formation = this.formationProgress ? this.formationProgress.formation : this.formationInfos;
			return showRedactionNavItems(this.currentRoute.name) || formation.for_session;
		},
	},
	async created() {
		this.userHaveFormations = await this.checkUserHaveFormation();
	},
	methods: {
		showTopicDropdown() {
			const routeNames = [
				'Redaction Dashboard',
				'Redaction Dashboard Index',
				'Redaction Dashboard Parcours Detail',
				'Redaction Dashboard Chapter Detail',
				'Dashboard',
				'Profile',
				'Profile Index',
				'Parcours Detail',
				'Category Detail',
				'Leaderboard Weekly',
			];
			return routeNames.includes(this.currentRoute.name);
		},
		async checkUserHaveFormation() {
			if (localStorage.getItem('token') == null) return null;

			axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
			const formationRes = await axios.get('/api/formation');
			return formationRes.data.formations.length > 0;
		},
	},
	watch: {
		async '$route.name'() {
			if (this.userHaveFormations == null) this.userHaveFormations = await this.checkUserHaveFormation();
		},
	},
};
</script>

<style lang="scss" scoped>
.v-list-item {
	min-height: auto !important;
}
.navBarLink {
	font-weight: 500;
	letter-spacing: 0.0892857143em;
	text-transform: uppercase;
	font-size: 12px;
	color: #989fa9;
}

::v-deep {
	.navbar {
		height: fit-content !important;
		.v-toolbar__content {
			height: fit-content !important;
		}
		h2 {
			border-left: 1px solid #989fa9;
		}
	}
}

@media screen and (max-width: 600px) {
	.navbar {
		position: relative;
		z-index: 10;
	}
	a {
		&:first-child:not(#login) {
			border-right: none;
		}
	}
	.v-list {
		padding: 0px;
	}
	.v-list-item {
		position: absolute;
		background: white;
		right: 0px;
		padding: 10px;
		margin-right: 10px;
		border: 1px solid #989fa9;
	}
}
</style>
