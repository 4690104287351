import axios from 'axios';

const state = () => ({
	testScore: null, // This attempt test score
});

const getters = {
	testScore: (state) => state.testScore,
};
const mutations = {
	setTestScore(state, testScore) {
		state.testScore = testScore;
	},
	clearStore(state) {
		state.testScore = null;
	},
};

const actions = {
	async fetchTestScoreById(store, { testScoreId }) {
		const res = await axios.get('/api/testScores/' + testScoreId);
		return res.data.testScore;
	},
	async fetchTestScores(store, { testId, userId, projectFields }) {
		const res = await axios.post('/api/testScores/search', { testId, userId, projectFields });
		return res.data.testScores;
	},
	async createTestScore(store, { testId, userId, testBankId, attempt }) {
		const res = await axios.post('/api/testScores/create', { testId, userId, testBankId, attempt });
		store.commit('setTestScore', res.data.testScore);
		return res.data.testScore;
	},
	async updateTestScore(store, { toUpdateTestScore }) {
		await axios.put('/api/testScores/' + toUpdateTestScore._id, {
			status: toUpdateTestScore.status,
			score: toUpdateTestScore.score,
			durationPerTrack: toUpdateTestScore.duration_per_track,
			userAnswers: toUpdateTestScore.user_answers,
		});
		store.commit('setTestScore', toUpdateTestScore);
	},
	clear(store) {
		store.commit('clearStore');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
