import axios from 'axios';

const state = () => ({
	familyTags: null,
});

const getters = {
	familyTags: (state) => state.familyTags,
};

const mutations = {
	updateFamilyTags(state, familyTags) {
		state.familyTags = familyTags;
	},
};

const actions = {
	async getAllFamilyTags(store) {
		const res = await axios.get('/api/family-tags');
		store.commit('updateFamilyTags', res.data.data);
		return res.data.data;
	},
	async getRuleTagByIds(store, { ruleTagIds }) {
		const res = await axios.post('/api/rules/search', { ids: ruleTagIds });
		return res.data.ruleTags;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
