import axios from 'axios';

const state = () => ({
	exercicesInfos: {},
	storyID: null,
	storyInfos: [],
	exercicesData: 0,
	formationInfos: {},
	questionID: null,
	score_per_question: [],
	score_per_story: [],
	allExerciceFromFormationCategory: null,
	chatGptExplanation: 'OFF',
	noFeedbacksExercices: false,
});

const getters = {
	exerciceType: (state) => {
		if (state.exercicesInfos.type_id) {
			let exerciceType =
				state.exercicesInfos.type_id.name.charAt(0).toUpperCase() + state.exercicesInfos.type_id.name.slice(1);
			return exerciceType;
		} else {
			return 'Default';
		}
	},
	exerciceInfos: (state) => {
		return state.exercicesInfos;
	},
	stories: (state) => {
		let arrayStory = [];
		state.exercicesInfos.story_id.map((element) => {
			let typeStory =
				element.type_id == null
					? 'Default'
					: element.type_id.name.charAt(0).toUpperCase() + element.type_id.name.slice(1);
			arrayStory.push({ id: element._id, storyType: typeStory, infos: element });
		});
		return arrayStory;
	},
	listStories: (state) => {
		return state.exercicesInfos.story_id;
	},
	questionsInStory: (state) => {
		let currentStory = state.exercicesInfos.story_id.find((story) => story._id === state.storyID);
		let questions = currentStory.questions_id.filter((question) => {
			return question.format_question === 2 || question.format_question === 3;
		});
		return questions;
	},
	questionsTrainingInStory: (state) => {
		let currentStory = state.exercicesInfos.story_id.find((story) => story._id === state.storyID);
		let questions = currentStory.questions_id.filter((question) => question.format_question === 1);
		return questions;
	},
	questionDetails: (state) => {
		let currentStory = state.exercicesInfos.story_id.find((story) => story._id === state.storyID);
		let questions = currentStory.questions_id.find((question) => question._id === state.questionID);
		return questions;
	},
	resultExercice: (state) => {
		let array = [];
		let arrayResultPerStory = state.score_per_question.reduce((acc, obj) => {
			const key = obj['story_id'];
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(obj);
			return acc;
		}, {});
		for (const i in arrayResultPerStory) {
			array.push({ story_id: i, questions_id: arrayResultPerStory[i] });
		}
		return array;
	},
	resultStory: (state) => {
		return state.score_per_story;
	},
	allExerciceFromFormationCategory: (state) => {
		return state.allExerciceFromFormationCategory;
	},
	scoreExercice: (state) => {
		let score = 0;
		state.score_per_question.map((element) => {
			if (element.is_good_answer === true) {
				score++;
			}
		});
		return score / state.score_per_question.length;
	},
	scoreStory: (state) => {
		let score = 0;
		state.score_per_story.map((element) => {
			if (element.is_good_answer === true) {
				score++;
			}
		});
		return score / state.score_per_question.length;
	},
	formation: (state) => {
		return state.formationInfos;
	},
	performanceExercice: (state) => {
		let array = [];
		if (state.exercicesData) {
			state.exercicesData.story_score.map((score) => {
				let scoreTotal = 0;
				let lengthQs = 0;
				score.questions_id.map((element) => {
					if (element.is_good_answer === true) {
						scoreTotal++;
					}
					lengthQs++;
				});
				array.push({ story_name: score.story_id.rule_tags_id.name, scoreTotal, lengthQs });
			});
		}
		return array;
	},
	exerciceDone: (state) => {
		return state.exercicesData != null ? state.exercicesData : null;
	},
	chatGptExplanation: (state) => {
		return state.chatGptExplanation;
	},
	getNoFeedbacksExercices: (state) => {
		return state.noFeedbacksExercices;
	},
};
const mutations = {
	exercicesInfos(state, infos) {
		state.exercicesInfos = infos;
	},
	allExerciceFromFormationCategory(state, infos) {
		state.allExerciceFromFormationCategory = infos;
	},
	exercicesData(state, infos) {
		state.exercicesData = infos;
	},
	formationInfos(state, infos) {
		state.formationInfos = infos;
	},
	questionsInStory(state, storyID) {
		state.storyID = storyID;
	},
	coursInQuestion(state, questionID) {
		state.questionID = questionID;
	},
	scoreInStory(state, infos) {
		state.score_per_story = infos;
	},
	scoreToSet(state, { idStory, question, answer }) {
		state.score_per_question.push({
			story_id: idStory,
			question_id: question._id,
			answer: answer,
			is_good_answer: answer.trim() == question.answers[question.good_answer].trim(),
		});
	},
	scoreToReset(state) {
		state.score_per_question = [];
	},
	chatGptExplanation(state, infos) {
		state.chatGptExplanation = infos;
	},
	setNoFeedbacksExercices(state, noFeedbacksExercices) {
		if (!noFeedbacksExercices.length) state.noFeedbacksExercices = true;
	},
	resetNoFeedbacksExercices(state) {
		state.noFeedbacksExercices = false;
	},
};
const actions = {
	setScoreExercice(store, { idStory, answer, question }) {
		store.commit('scoreToSet', { idStory, answer, question });
	},
	resetScoreExercice(store) {
		store.commit('scoreToReset');
	},
	getQuestionsStory(store, storyID) {
		store.commit('questionsInStory', storyID);
	},
	getCoursQuestions(store, questionID) {
		store.commit('coursInQuestion', questionID);
	},
	async getExercice(store, { idFormation, idExercice }) {
		const exercice = await axios.get('/api/exercice/' + idFormation + '/' + idExercice);
		store.commit('exercicesInfos', exercice.data.exercice);
		store.commit('exercicesData', exercice.data.exercicesData);
		store.commit('formationInfos', exercice.data.formation);
		store.commit('allExerciceFromFormationCategory', exercice.data.allExerciceFromFormationCategory);
		store.commit('chatGptExplanation', exercice.data.chatGptExplanation);
	},
	async rulesUnderstanding(store, { coursId, questionId }) {
		const res = await axios.post('/api/exercice/rules-understanding', {
			questionId,
			coursId,
			formationId: store.state.formationInfos._id,
		});
		return res;
	},
	async postScoreExercice(store, { score, idFormation, idExercice, scoreGlobal }) {
		await axios.post('/api/exercice/score', {
			score,
			idFormation,
			idExercice,
			scoreGlobal,
		});
	},
	async postScoreStory(store, { scoreStory, idFormation, idExercice }) {
		const res = await axios.post('/api/exercice/score/story', {
			scoreStory,
			idFormation,
			idExercice,
		});
		store.commit('scoreInStory', res.data.exercicesDone.story_score);
	},
	async getQuestionExplanationFromAI(store, { question, config }) {
		const res = await axios.post('/api/exercice/question/explanation', { question }, { ...config });
		return res.data;
	},
	async getNoFeedbacksExercices(store, { exerciceId, after, rating, userId }) {
		if (exerciceId == null || exerciceId == 'undefined' || exerciceId == 'null' || exerciceId == '') return;

		const res = await axios.get('/api/exercice/feedbacks', {
			params: {
				exerciceId,
				after,
				rating,
				userId,
			},
		});
		store.commit('setNoFeedbacksExercices', res.data.data);
	},
	resetNoFeedbacksExercices(store) {
		store.commit('resetNoFeedbacksExercices');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
