<template>
	<v-badge v-if="haveToDoTest" color="error" dot>
		<span>Mes défis</span>
	</v-badge>
	<span v-else>Mes défis</span>
</template>

<script>
import { mapGetters } from 'vuex';
import { decideTestStatus } from '@/utils/test.js';

export default {
	name: 'TestHomePageNavItem',
	data() {
		return { haveToDoTest: false };
	},
	computed: {
		...mapGetters('tests', {
			allTestsOfUser: 'allTestsOfUser',
		}),
		...mapGetters('profile', { profileInfos: 'profileInfos' }),
	},
	async mounted() {
		if (this.allTestsOfUser != null) {
			this.checkHaveToDoTest();
			return;
		}

		await this.fetchAndSetAllUserTests(this.profileInfos._id);
	},
	methods: {
		findTestScoresByTestId(testScores, testId) {
			if (testId == null) return null;
			if (testScores == null) return null;
			return testScores.filter((testScore) => testScore.test_id === testId);
		},
		async fetchAndSetAllUserTests(userId) {
			if (userId == null) return;
			// Fetch all user tests and testScores to update each test status
			let _userTests = await this.$store.dispatch('tests/fetchAllUserTests', {
				userId: userId,
			});
			_userTests = _userTests.filter((test) => test.canceled_at == null);

			const testScores = await this.$store.dispatch('testScores/fetchTestScores', {
				userId: userId,
			});

			// Find status for _userTests
			_userTests.forEach((test) => {
				const _testScores = this.findTestScoresByTestId(testScores, test._id);
				const statusOfTheTest = decideTestStatus(test, _testScores);
				test.status = statusOfTheTest;
			});

			this.$store.dispatch('tests/setAllTestsOfUserAction', _userTests);
		},
		checkHaveToDoTest() {
			if (this.allTestsOfUser == null) return false;
			const todoTest = this.allTestsOfUser.find((test) => test.status === 'in progress');
			this.haveToDoTest = todoTest != null;
		},
	},
	watch: {
		async profileInfos() {
			await this.fetchAndSetAllUserTests(this.profileInfos._id);
		},
		allTestsOfUser: {
			handler() {
				this.checkHaveToDoTest();
			},
			deep: true,
		},
	},
};
</script>

<style scoped lang="scss"></style>
