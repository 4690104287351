/**
 *
 * @param {TestScore[]} testScores
 * @param {string} testScoreStatus doing | completed
 * @returns
 */
export const findLastTestScoreByStatus = (testScores, testScoreStatus) => {
	const lastTestScore = testScores
		.filter((tesScore) => tesScore.status === testScoreStatus)
		.sort((a, b) => b.attempt - a.attempt)[0];

	return lastTestScore;
};

/**
 *
 * @param {TestScore[]} testScores
 * @returns {TestScore | null}
 */
export const getLastTestScore = (testScores) => {
	if (testScores == null || testScores.length === 0) return null;

	const _lastPassedTestScore = findLastTestScoreByStatus(testScores, 'completed');
	if (_lastPassedTestScore) {
		return _lastPassedTestScore;
	}

	const _lastDoingTestScore = findLastTestScoreByStatus(testScores, 'doing');
	if (_lastDoingTestScore) {
		return _lastDoingTestScore;
	}

	return null;
};
