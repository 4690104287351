import Vue from 'vue';
import Vuex from 'vuex';
import profile from './modules/profile';
import accountManager from './modules/accountManager';
import exercice from './modules/exercice';
import rulesDashboard from './modules/rulesDashboard';
import rules from './modules/rules';
import reportCategory from './modules/reports/category';
import onboarding from './modules/onboarding';
import formation from './modules/formation';
import assistant from './modules/assistant';
import statuts from './modules/statuts';
import resources from './modules/resources';
import stories from './modules/stories';
import familyTags from './modules/familyTags';
import leaderboard from './modules/leaderboard';
import sessions from './modules/sessions';
import questionnaires from './modules/questionnaires';
import tracks from './modules/tracks';
import topics from './modules/topics';
import sessionScore from './modules/sessionScore';
import appState from './modules/appState';
import feedbackSession from './modules/feedbackSession';
import parcours from './modules/parcours';
import tests from './modules/test';
import testScores from './modules/testScore';
import streak from './modules/streak';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		profile,
		accountManager,
		exercice,
		rulesDashboard,
		rules,
		reportCategory,
		onboarding,
		formation,
		assistant,
		statuts,
		resources,
		stories,
		familyTags,
		leaderboard,
		sessions,
		questionnaires,
		tracks,
		topics,
		sessionScore,
		appState,
		feedbackSession,
		parcours,
		tests,
		testScores,
		streak,
	},
});
