import axios from 'axios';
import moment from 'moment';
import { TEST_STATUS } from '@/constants/test.js';
import { findLastTestScoreByStatus } from '@/utils/testScore.js';

export const checkUserHaveTest = async () => {
	if (localStorage.getItem('token') != null) {
		axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
	}
	// Call API get user
	const userRes = await axios.get('/api/profile/user-profile');
	const user = userRes.data.profile;

	// Call API get user tests
	const userTestsRes = await axios.get('/api/tests/user/' + user._id);
	const tests = userTestsRes.data.tests;

	return tests.length > 0;
};

export const decideTestStatus = (test, testScores) => {
	if (test.canceled_at != null) return TEST_STATUS.canceled;
	if (moment().diff(moment(test.begin_date)) < 0) return TEST_STATUS.scheduled;

	if (!testScores || testScores.length === 0) {
		// Didnt do the test (didnt finish the test)
		if (!test.due_date || moment().diff(moment(test.due_date)) < 0) {
			// Test dont have due date OR due date is in future
			return TEST_STATUS.inProgress;
		}
		if (test.due_date && moment().diff(moment(test.due_date)) >= 0) {
			// Test is due. User dont have any test score
			return TEST_STATUS.notComplete;
		}
	} else {
		// User did the test. Maybe passed or not passed
		const lastPassedTestScore = findLastTestScoreByStatus(testScores, 'completed');

		if (!lastPassedTestScore) {
			if (testScores.length === test.attempts) return TEST_STATUS.notComplete; // Out of attempt and dont have any completed
			if (!test.due_date || moment().diff(moment(test.due_date)) < 0) {
				// Test dont have due date OR due date is in future
				return TEST_STATUS.inProgress;
			}
			if (test.due_date && moment().diff(moment(test.due_date)) >= 0) {
				// Test is due. User dont have any test score
				return TEST_STATUS.notComplete;
			}
		} else {
			return TEST_STATUS.completed;
		}
	}

	return 'unknow status';
};
